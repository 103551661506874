.expanded {
  background-color: $widget-light;

  td {
    padding: 20px 30px 30px 30px;
  }

  &__grid {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and(max-width: 920px) {
      align-items: stretch;
    }

    &-row {
      display: flex;
      @media only screen and(max-width: 920px) {
        flex-direction: column;
      }
    }
    &-column {
      margin-right: 30px;
      width: 300px;
      padding: 0;

      @media only screen and(max-width: 920px) {
        width: 100%;
      }
    }

    &-column-wide {
      width: 300px;
    }

    &-widget {
      background-color: #f5f5f5;
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
      border-radius: 2px;
      width: 300px;
      height: 250px;
      margin-bottom: 30px;

      @media only screen and(max-width: 920px) {
        width: 100%;
      }
    }

    &-widget-small {
      height: 90px;
    }

    &-widget-medium {
      height: 130px;
    }

    &-widget-wide {
      width: 500px;
    }

    &-chart {
      background-color: #f5f5f5;
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
      border-radius: 2px;
      width: 300px;
      margin-bottom: 30px;

      @media only screen and(max-width: 920px) {
        width: 100%;
      }
    }

    &-map {
      background-color: $widget-dark;
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
      border-radius: 2px;
      width: 300px;
      height: 250px;
      margin-bottom: 30px;

      @media only screen and(max-width: 920px) {
        width: 100%;
      }
    }

    &-button-group {
      display: flex;

      @media only screen and(max-width: 920px) {
        flex-direction: column;
        justify-content: center;
      }
    }

    &-button {
      width: 145px;
      height: 40px;
      background-color: #00b1d0;
      border: none;
      border-radius: 4px;
      color: white;
      cursor: pointer;
      margin-bottom: 10px;

      &:hover {
        background-color: #29bed9;
      }

      @media only screen and(max-width: 920px) {
        width: 100%;
      }
    }

    &-issue-button {
      width: 145px;
      height: 40px;
      background-color: #c35b59;
      border: none;
      border-radius: 4px;
      color: white;
      cursor: pointer;
      margin-bottom: 10px;

      &:hover {
        background-color: #c66b69;
      }

      @media only screen and(max-width: 920px) {
        width: 100%;
      }
    }
  }
}
