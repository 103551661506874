.profile {
  background-color: #ffffff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 18px;

  &__field {
    color: #151515;
    margin-bottom: 10px;
    padding: 8px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  }
}
