table {
  width: 100%;
  background: white;
  border-collapse: collapse;
  box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.25);

  th {
    border-bottom: 2px solid $widget-light;
    font-weight: 500;
  }

  td {
    font-weight: 400;
  }

  th,
  td {
    color: #151515;
    height: 45px;
    text-align: left;
    text-indent: 10px;
  }

  tr:not(.expanded-row):hover > td {
    background: #dbe2e9;
  }
}
