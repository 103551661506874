.dropdown {
  &-icon {
    cursor: pointer;
  }

  &-list {
    background-color: #151515;
    border-radius: 2px;
    margin-top: 0.5rem;
    max-height: 175px;
    overflow: auto;
    padding-bottom: 0.5rem;
    position: absolute;
    right: 0;
    white-space: nowrap;
  }

  &-list-group {
    padding: 0.25rem 0.25rem 0.25rem 1rem;
  }

  &-list-group:first-child {
    padding-top: 0.5rem;
  }

  &-list-item {
    padding-left: 1.75rem;
    padding-right: 1.5rem;
  }
}

.select {
  margin: 10px 0;
  min-width: 120px;

  &__label {
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    width: inherit;
    min-height: 40px;
    font-size: 16px;
    padding: 8px;
    margin: 10px 0;
    border: 1px solid #c2c2c2;
    border-radius: 5px;
  }

  &__options {
    position: absolute;
    min-height: 30px;
    max-height: 200px;
    width: inherit;
    background-color: #fff;
    border: 1px solid #363636;
    z-index: 10000;
    overflow-y: auto;
    overflow-x: hidden;

    &-item {
      padding: 5px 10px;
    }
  }

  &__error {
    color: #ae431e;
    font-size: 12px;
    font-weight: 300;
  }
}

.visible {
  display: inherit;
}

.closed {
  display: none;
}

.disabled {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #363636;
  border-radius: 5px;
  background-color: whitesmoke;
  font-size: 17px;
  font-weight: 600;
}

input[type="checkbox"].toggle {
  opacity: 0;
  position: absolute;
  left: -9000px;
  top: -9000px;
}

input[type="checkbox"].toggle + label {
  color: rgb(153, 153, 153);
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

input[type="checkbox"].toggle + label::before {
  content: "";
  width: 2em;
  height: 0.5em;
  background-color: #363636;
  border-radius: 1em;
  margin-right: 0.5em;
  transition: background-color 200ms ease-in-out;
}

input[type="checkbox"].toggle + label::after {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  content: "";
  font-size: 0.5em;
  height: 0.9em;
  left: 0.2em;
  width: 1.8em;
  height: 1.8em;
  background-color: #747474;
  border-radius: 1em;
  transition: background-color 200ms ease-in-out, transform 200ms ease-in-out;
}

input[type="checkbox"].toggle:checked + label {
  color: white;
}

input[type="checkbox"].toggle:checked + label::before {
  background-color: #3b5c74;
}

input[type="checkbox"].toggle:checked + label::after {
  content: "";
  transform: translateX(100%);
  background-color: #39a5f1;
}

input[type="checkbox"].toggle:checked.green + label::after {
  background-color: #2ca797;
}

input[type="checkbox"].toggle:checked.green + label::before {
  background-color: #325f59;
}

input[type="checkbox"].toggle:disabled + label {
  color: #777;
}

input[type="checkbox"].toggle:disabled + label::before {
  background-color: #ccc;
}

input[type="checkbox"].toggle:disabled + label::after {
  background-color: #777;
}
