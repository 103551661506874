.input {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__label {
    font-size: 16px;
    font-weight: 500;
    padding: 10px;
  }

  &-error {
    border: 1px solid #ae431e;
  }

  &__error {
    color: #ae431e;
    font-size: 12px;
    font-weight: 300;
  }

  &__field {
    &-disabled {
      border: none;
      background-color: whitesmoke;
      font-size: 17px;
      font-weight: 600;
    }
    min-height: 30px;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #c2c2c2;
    @include avoid-outline;
  }
}

.textarea {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 15px 0;

  &__label {
    font-size: 16px;
    font-weight: 500;
    padding: 10px 0;
  }

  &-error {
    border: 1px solid #ae431e;
  }

  &__error {
    color: #ae431e;
    font-size: 12px;
    font-weight: 300;
  }

  &__field {
    &-disabled {
      border: none;
      background-color: whitesmoke;
      font-size: 17px;
      font-weight: 600;
    }
    resize: none;
    min-height: 100px;
    border-radius: 5px;
    padding: 10px;
    @include avoid-outline;
  }
}
