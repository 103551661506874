.efficiency-widget {
  color: white;

  span {
    color: white;
    border-bottom: 2px solid #009dba;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;

    h3 {
      margin: 0;
    }
  }
}
