.trips-container {
  display: flex;
  flex-direction: row;

  &-content {
    opacity: 0;
    width: 0px;
    transition: width 0.5s, opacity 0.5s;
    color: #151515;
    justify-content: center;
    margin-left: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
    cursor: pointer;
  }

  &:hover &-content {
    width: 30px;
    opacity: 1;
  }
}

.console-container {
  height: 100vh;
  display: flex;
  flex-direction: column;

  &-chart {
    padding: 10px 30px;
    background: #151515;
  }
}

.console {
  position: absolute;
  left: 50px;
  right: 50px;
  top: 10px;
  padding: 10px;
  z-index: 10000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;

  &__general {
    background: #151515;
    color: #fff;
    padding: 10px;
    text-align: center;
    border-radius: 4px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &__trips {
    position: absolute;
    top: 80px;
    left: 110px;
    z-index: 10000;
    display: flex;
    flex-direction: column;

    &-trip {
      border-radius: 4px;
      padding: 5px;
      margin-bottom: 5px;
      margin-right: 5px;
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
      color: #fff;
      background-color: #151515;
      cursor: pointer;

      &.focused {
        background-color: #60666e;
      }
    }
  }

  &__btn-container {
    position: absolute;
    top: 10px;
    left: 110px;
    z-index: 10000;
    display: flex;
    flex-direction: column;
  }

  &__btn {
    height: 30px;
    width: 30px;
    padding: 0 !important;
    margin-top: 10px !important;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #151515 !important;
    color: white !important;

    &:first-child {
      margin-top: 20px !important;
    }
  }

  &__legend {
    position: absolute;
    bottom: 30px;
    right: 9px;
    width: 150px;
    padding: 12px 12px 6px 12px;
    background-color: #151515;
    color: white;
    z-index: 10000;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }
}

.trip-dashboard {
  position: absolute;
  left: 120px;
  top: 200px;
  width: 450px;
  height: 450px;
  z-index: 10000;
  color: #fff;
  background-color: #222;
  border-radius: 4px;
  padding: 24px;
  overflow: auto;

  &__details {
    div {
      margin: 8px 0;
    }

    span {
      display: block;
    }

    &-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-chart {
      min-width: 220px;
    }
  }

  &__divider {
    border-top: 1px solid rgb(15, 91, 115);
    border-bottom: 1px solid rgb(15, 91, 115);
    padding: 10px 0;
    margin: 10px 0;
  }
}
