.settings {
  min-height: 600px;
  background-color: #ffffff;
  color: #151515;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  box-shadow: 4px 4px 2px rgba(0, 0, 0, 0.25);

  &__changer {
    &-container {
      width: 30%;
    }
  }

  &__field {
    display: flex;
    align-items: center;
  }

  &__edit {
    cursor: pointer;
    margin-left: 20px !important;
    font-size: 18px !important;
  }

  &__section {
    &-divider {
      margin: 20px 0 10px !important;
    }
  }

  &__passwords {
    margin: 10px 0;
  }
}

.groups {
  width: 26.5%;
  height: 100px;

  &__field {
    margin: 0 0 1em;
    &-input {
      height: 50px;
      margin: 50px 0;
    }
  }

  &__data {
    display: flex;
    flex-wrap: wrap;
    margin: 1em 0;

    &-element {
      background-color: #c2c2c2;
      color: #222;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0.5em;

      padding: 0.8em;
      border-radius: 20px;
      min-width: 120px;

      &-name {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}
