.issue {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  &__input {
    margin: 0 10px;
  }
}

.issue-modal {
  width: 100%;
  color: #333;

  &__header {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 2px solid #c2c2c2;
    &-label {
      font-size: 18px;
      font-weight: 600;
    }
  }

  &__body {
    padding: 10px;
  }

  &-button {
    width: 0;
    height: 0;
    margin: 0 !important;
    padding: 0 !important;
    background-color: inherit !important;

    .check .icon {
      margin: 0 !important;
    }
  }
}
