html,
body {
  margin: 0;
  background-color: #3d4b56;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

form {
  margin: 0;
  padding: 0;
}

$background-color: #F5F5F5;
$widget-light: #dbe2e9;
$widget-lighter: #dbe2e9;
$widget-dark: #dbe2e9;

.text.white {
  color: #151515;
}
.text.grey {
  color: #cccccc;
}
.text.black {
  color: #1b1c1d;
}
.text.yellow {
  color: #f2c61f;
}
.text.teal {
  color: #00b5ad;
}
.text.red {
  color: #d95c5c;
}
.text.purple {
  color: #564f8a;
}
.text.pink {
  color: #d9499a;
}
.text.orange {
  color: #e07b53;
}
.text.green {
  color: #5bbd72;
}
.text.blue {
  color: #3b83c0;
}
svg {
  overflow: visible;
}

.d-flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.text-hidden {
  text-overflow: ellipsis;
}

.m-5 {
  margin: 5px !important;
}

.m-10 {
  margin: 10px;
}

.h-35 {
  min-height: 35px;
}

.cursor-pointer {
  cursor: pointer;
}

.button {
  color: #fff !important;
  &-default {
    background-color: rgb(0, 157, 186) !important;
  }

  &-danger {
    background-color: #ae431e !important;
  }
}

@mixin avoid-outline {
  outline: none;
  outline-offset: 0;
}

@import "./components/components";
@import "./pages/pages";
@import "./templates/templates";
