.heatmap {
  &-title {
    color: #151515;
    margin: 0;
    margin-bottom: 0.5rem;
  }

  &-tooltip {
    background-color: #dbe2e9;
    border-radius: 4px;
    padding: 8px 16px;
    color: #151515;
    pointer-events: none;
    position: absolute;
    z-index: 1000;

    &-text {
      padding: 0;
      margin: 0;
    }
  }

  &__year {
    background-color: inherit;
    border: none;
    color: #151515;
    font-size: 22px;
    font-weight: 600;
    outline: 0;
    padding: 10px 0;
    option {
      color: #151515;
      border: none;
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.616);
    }
  }
}
