.alert {
  border-radius: 50px;
  &-small {
    width: 10px;
    height: 10px;
  }

  &-medium {
    width: 20px;
    height: 20px;
  }

  &-big {
    width: 30px;
    height: 30px;
  }

  &-success {
    background-color: #519259;
  }

  &-warning {
    background-color: #f6d860;
  }

  &-error {
    background-color: #ae431e;
  }
}
