.private {
  display: flex;
  padding-top: 0;
  min-height: 100vh;
  background: $background-color;
  font-family: "Poppins", sans-serif;

  &__content {
    top: 0px;
    left: 0px;
    width: 100%;
    background: $background-color;
    margin-left: 100px;
  }
}

.public {
  background: $background-color;
  width: 100%;
  height: 100vh;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Poppins", sans-serif;

  &__content {
    background: #fff;
    width: 340px;
    color: #000;
    border-radius: 4px;
    margin: 20px 0px;
    padding: 20px;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  position: fixed;
  background: #151515;
  height: 100vh;
  width: 100px;
  color: #fff;
  top: 0;
  padding-top: 0px;
  box-shadow: 6px 0 6px rgba(0, 0, 0, 0.1);
  z-index: 10000;

  &__item {
    text-align: center;
    padding: 15px 0;
    cursor: pointer;
    color: #fff;

    p {
      margin-top: 4px;
    }

    &:hover {
      background: $widget-light;
    }
  }

  &__container {
    flex-basis: 100;
    flex-grow: 0;
    flex-shrink: 0;
  }
}
