$content-margin: 15px;

.calendar-container {
  background: white;
  box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
  border-radius: 2px;
  padding: 20px 40px;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 600px) {
  .calendar-container {
    height: 100px;
  }
}
