.ranking-widget {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  span {
    color: white;
    border-bottom: 2px solid #009dba;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h3 {
      margin: 0;
    }
  }

  p {
    margin: 2px;
    padding: 0px;
  }
}

.ranking-widget-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  margin-top: 10px;
}
