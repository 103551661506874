.maintenance-widget {
  color: white;
  span {
    color: white;
    border-bottom: 2px solid #009dba;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h3 {
      margin: 0;
    }
  }

  ul {
    margin: 0;
  }
}

.maintenance-widget-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  margin-top: 10px;
}
