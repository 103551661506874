.alert-widget {
  color: white;
  span {
    color: white;
    border-bottom: 2px solid #009dba;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h3 {
      margin: 0;
    }
  }
  p {
    color: white;
    margin: 2px;
    padding: 0px;
  }
}

.alert-widget-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
