.statistics-widget {
  color: white;
  span {
    color: white;
    border-bottom: 2px solid #009dba;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h3 {
      margin: 0;
    }
  }
}

.statistics-widget-grid {
  color: white;
  display: grid;
  grid-template-columns: 100px auto;
  grid-template-rows: 1fr 1fr;
  margin-top: 10px;
}

.statistics-row {
  display: flex;
  flex-direction: column;

  p {
    color: white;
    text-align: center;
    margin: 0;
  }

  h2 {
    color: white;
    text-align: center;
    margin: 0;
  }
}
