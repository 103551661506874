.description {
  width: 100%;
  background-color: #000;
  color: white;
  &__header {
    height: 40%;
    display: flex;
    justify-content: center;
  }

  &__charts {
    height: 500px;
    padding: 50px;
    display: flex;
    justify-content: space-between;
    &-map {
      width: 450px;
      height: 450px;
    }

    &-spider {
      width: 450px;
      height: 500px;
    }

    &-additional {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__trip-chart {
    padding: 30px;
  }
}

.description-logs {
  padding: 35px;
  min-width: 300px;
}

.w-60 {
  width: 60%;
}
