.chart {
  h3 {
    margin: 0;
  }

  &-context {
    background-color: rgba(255, 255, 255, 0.04);
    border-radius: 4px;
  }

  &-header {
    color: rgb(230, 230, 230);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
  }

  &-tools {
    color: white;
    display: flex;
    position: relative;
    align-items: center;

    &-arrow {
      padding: 0 5px 0 5px;
      cursor: pointer;
    }

    &-dropdown {
      padding-left: 12px;
    }
  }
}

.tooltip {
  background-color: rgba(39, 39, 39, 0.95);
  border-radius: 4px;
  color: white;
  padding: 8px 16px;
  pointer-events: none;
  position: absolute;
  z-index: 1000;

  &-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    min-width: 150px;
  }

  &-header {
    margin-bottom: 10px;
  }

  &-square {
    width: 12px;
    height: 12px;
    margin-right: 8px;
  }

  &-circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 6px;
  }

  &-text {
    padding: 0;
    margin: 0;
  }
}

.eventTooltip {
  color: white;
  pointer-events: none;
  position: absolute;
  z-index: 10000;
}

.eventContainer {
  background-color: $widget-lighter;
  border-radius: 2px;
  margin-bottom: 6px;
  padding: 8px 16px;
  width: 150px;

  &-title {
    font-weight: bold;
    margin: 0;
    padding: 0;
    margin-bottom: 0.5rem;
  }

  &-content {
    display: flex;
    margin: 0;
    padding: 0;
  }
}
