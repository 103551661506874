.container {
  margin: 20px;
}

.dashboard-screen {
  display: grid;
  grid-template-columns: repeat(auto-fit, 200px);
  grid-template-rows: repeat(6, 200px);
  gap: 10px;
}

.dashboard-item {
  &-1by1 {
    padding: 10px;
  }

  &-2by2 {
    padding: 10px;
    grid-column: span 2;
    grid-row: span 2;
  }

  &-2by1 {
    padding: 10px;
    grid-column: span 2;
    grid-row: span 1;
  }

  &-3by1 {
    padding: 10px;
    grid-column: span 3;
    grid-row: span 1;
  }

  &-1by2 {
    padding: 10px;
    grid-column: span 1;
    grid-row: span 2;
  }

  &-3by2 {
    padding: 10px;
    grid-column: span 3;
    grid-row: span 2;
  }

  &-3by3 {
    padding: 10px;
    grid-column: span 3;
    grid-row: span 3;
  }
}

.dashboard-dialog {
  input[type="checkbox"] {
    vertical-align: middle;
    position: relative;
    bottom: 1px;
    margin-right: 15px;
  }

  label {
    display: block;
  }
}
