.modal {
  display: flex; /* Hidden by default */
  justify-content: space-around;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  z-index: 1001;

  &-content {
    min-width: 30%;
    background-color: white;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 4px;
    z-index: 1001;
  }

  div {
    form {
      input[type="text"],
      select {
        width: 100%;
        padding: 12px 20px;
        margin: 8px 0;
        display: block;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
      }

      input[type="checkbox"] {
        margin-right: 5px;
      }

      input[type="submit"] {
        width: 100%;
        background-color: #333;
        color: white;
        padding: 14px 20px;
        margin: 8px 0;
        border: none;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: lightgray;
        }
      }

      textarea {
        width: 100%;
        padding: 10px;
      }
    }

    button {
      background-color: rgb(190, 7, 18);
      color: white;
      border-radius: 4px;
      border: none;
      padding: 10px;
      width: 100%;
    }
  }
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;

  &:hover,
  &:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
}

.activity {
  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: rgb(0, 0, 0);
    padding: 20px 5px;
    margin: 20px 0;
    border-bottom: 1px solid #c2c2c2;

    &-element {
      text-align: center;
      font-size: 17px;
      font-weight: 500;
    }
  }

  &__form {
    color: rgb(0, 0, 0);
  }
}
