.tabs {
  display: flex;
  justify-content: space-between;
  padding: 0 0.8rem;

  &__item {
    flex: 1;
    transition: all 0.3s;
    border-bottom: 1px solid transparent;
    cursor: pointer;
    color: #aaa;

    h2 {
      padding-bottom: 6px;
      text-align: center;
      font-size: 1.5rem;
    }

    &.active {
      color: #000;
      border-bottom: 1px solid #fff;
      z-index: 1000;
      margin-bottom: -1px;
    }
  }

  &__content {
    padding: 1rem;
    transition: all 0.3s;
  }
}
