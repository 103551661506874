.request-popup {
  .leaflet-popup-content-wrapper {
    border-radius: 4px;
    background: #151515;
    color: white;
  }

  .leaflet-popup-tip-container {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  .leaflet-popup-tip {
    background: #151515;
  }
}

.impact-marker {
  filter: invert(34%) sepia(76%) saturate(2613%) hue-rotate(332deg)
    brightness(92%) contrast(84%);
}

.sog-marker {
  filter: invert(43%) sepia(87%) saturate(289%) hue-rotate(153deg)
    brightness(97%) contrast(96%);
}

.boat-marker {
  filter: invert(80%) sepia(83%) saturate(443%) hue-rotate(340deg)
    brightness(96%) contrast(83%);
}
